import { useIsMobileWidth, useIsTabletWidth } from 'components/Breakpoint'

// TODO: Revisit this hook, think about better way to handle the truncation options
export const useTransactionHashTruncateOptions = () => {
  const { isMobileWidth } = useIsMobileWidth()
  const { isTabletWidth } = useIsTabletWidth()

  if (isMobileWidth) {
    return [6, 4] as const
  }

  if (isTabletWidth) {
    return [12, 12] as const
  }

  return [24, 24] as const
}
