import type { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { readContract, waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { ethers } from 'ethers'
import type { Address } from 'viem'
import { encodeFunctionData } from 'viem'
import { DEFAULT_VALUE, PESSIMISTIC_GAS_ESTIMATE_MULTIPLIER } from 'constants/common'
import { BRIDGE_MIN_GAS_LIMIT_ERC20 } from 'constants/network'
import type { Erc20TokenAsset } from 'hooks/useAssets'
import { wagmiConfig } from 'lib/wagmi'
import { getDepositERC20Arguments } from 'utils/bridge/getDepositERC20Arguments'
import { getDepositERC20FunctionName } from 'utils/bridge/getDepositERC20FunctionName'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'
import { isWstETHToken, isUSDTToken } from 'utils/common'
import { getAdjustedGasAmount } from './getAdjustedGasAmount'

export const depositERC20 = async ({
  amount,
  selectedAsset,
  account,
  setPendingTransactionHash,
  customReceivingAddress,
  provider,
  refetchGasFee,
}: {
  amount: string
  selectedAsset: Erc20TokenAsset
  account: Address
  setPendingTransactionHash: React.Dispatch<React.SetStateAction<Address | null>>
  customReceivingAddress?: Address
  provider: ethers.JsonRpcProvider
  refetchGasFee: (options?: RefetchOptions) => Promise<QueryObserverResult<string, Error>>
}) => {
  const isWstETH = isWstETHToken(selectedAsset.symbol)
  const isUSDT = isUSDTToken(selectedAsset.symbol)
  const { L1StandardBridge, L1StandardBridgeProxy } = getSmartContracts(selectedAsset.symbol)

  const bigIntAmount = BigInt(ethers.parseUnits(amount, selectedAsset.decimals_l1).toString())

  // @ts-expect-error - Erc20Token | ExternalErc20Token Union type
  const abi = JSON.parse(selectedAsset.abi_l1)

  const allowance = (await readContract(wagmiConfig, {
    address: selectedAsset.contract_address_l1 as Address,
    abi,
    functionName: 'allowance',
    args: [account, L1StandardBridgeProxy.address],
  })) as bigint

  const feeData = await provider.getFeeData()
  const gasPrice = feeData.gasPrice ?? 0n

  if (allowance < bigIntAmount) {
    const gas = await provider.estimateGas({
      from: account,
      to: selectedAsset.contract_address_l1 as Address,
      data: encodeFunctionData({
        abi,
        functionName: 'approve',
        args: [L1StandardBridgeProxy.address, isUSDT ? DEFAULT_VALUE : bigIntAmount],
      }),
    })

    const hash = await writeContract(wagmiConfig, {
      address: selectedAsset.contract_address_l1 as Address,
      abi,
      functionName: 'approve',
      args: [L1StandardBridgeProxy.address, isUSDT ? DEFAULT_VALUE : bigIntAmount],
      gas: getAdjustedGasAmount(gas, PESSIMISTIC_GAS_ESTIMATE_MULTIPLIER),
      gasPrice,
    })

    await waitForTransactionReceipt(wagmiConfig, { hash })

    if (isUSDT) {
      const usdtGas = await provider.estimateGas({
        from: account,
        to: selectedAsset.contract_address_l1 as Address,
        data: encodeFunctionData({
          abi,
          functionName: 'approve',
          args: [L1StandardBridgeProxy.address, bigIntAmount],
        }),
      })

      const usdtHash = await writeContract(wagmiConfig, {
        address: selectedAsset.contract_address_l1 as Address,
        abi,
        functionName: 'approve',
        args: [L1StandardBridgeProxy.address, bigIntAmount],
        gas: getAdjustedGasAmount(usdtGas, PESSIMISTIC_GAS_ESTIMATE_MULTIPLIER),
        gasPrice,
      })

      await waitForTransactionReceipt(wagmiConfig, { hash: usdtHash })
    }

    await refetchGasFee()
  }

  const isSameReceivingAddress = !customReceivingAddress || customReceivingAddress === account

  const gas = await provider.estimateGas({
    from: account,
    to: L1StandardBridgeProxy.address,
    data: encodeFunctionData({
      abi: L1StandardBridge.abi,
      functionName: getDepositERC20FunctionName(isWstETH, isSameReceivingAddress),
      // @ts-expect-error - Union type
      args: getDepositERC20Arguments(isWstETH, isSameReceivingAddress, {
        contractAddressL1: selectedAsset.contract_address_l1 as Address,
        contractAddressL2: selectedAsset.contract_address_l2 as Address,
        to: customReceivingAddress ?? account,
        amount: bigIntAmount,
        minGasLimit: BRIDGE_MIN_GAS_LIMIT_ERC20,
        extraData: '0x',
      }),
    }),
  })

  const depositERC20Hash = await writeContract(wagmiConfig, {
    address: L1StandardBridgeProxy.address,
    abi: L1StandardBridge.abi,
    functionName: getDepositERC20FunctionName(isWstETH, isSameReceivingAddress),
    // @ts-expect-error - Union type
    args: getDepositERC20Arguments(isWstETH, isSameReceivingAddress, {
      contractAddressL2: selectedAsset.contract_address_l2 as Address,
      contractAddressL1: selectedAsset.contract_address_l1 as Address,
      amount: bigIntAmount,
      to: customReceivingAddress ?? account,
      minGasLimit: BRIDGE_MIN_GAS_LIMIT_ERC20,
      extraData: '0x',
    }),
    gas: getAdjustedGasAmount(gas, PESSIMISTIC_GAS_ESTIMATE_MULTIPLIER),
    gasPrice,
  })

  setPendingTransactionHash(depositERC20Hash)

  const tx = await waitForTransactionReceipt(wagmiConfig, { hash: depositERC20Hash })

  return tx
}
