import type { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { readContract, waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { ethers } from 'ethers'
import type { Address } from 'viem'
import { encodeFunctionData } from 'viem'
import { PESSIMISTIC_GAS_ESTIMATE_MULTIPLIER } from 'constants/common'
import { BRIDGE_MIN_GAS_LIMIT_ERC20 } from 'constants/network'
import type { Erc20TokenAsset } from 'hooks/useAssets'
import { wagmiConfig } from 'lib/wagmi'
import { getAdjustedGasAmount } from 'utils/bridge/getAdjustedGasAmount'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'
import { getWithdrawERC20Arguments } from 'utils/bridge/getWithdrawERC20Arguments'
import { getWithdrawERC20FunctionName } from 'utils/bridge/getWithdrawERC20FunctionName'
import { isLsETHToken, isWstETHToken } from 'utils/common'

export const withdrawERC20 = async ({
  amount,
  selectedAsset,
  account,
  setPendingTransactionHash,
  customReceivingAddress,
  provider,
  refetchGasFee,
}: {
  amount: string
  selectedAsset: Erc20TokenAsset
  account: Address
  setPendingTransactionHash: React.Dispatch<React.SetStateAction<Address | null>>
  customReceivingAddress?: Address
  provider: ethers.JsonRpcProvider
  refetchGasFee: (options?: RefetchOptions) => Promise<QueryObserverResult<string, Error>>
}) => {
  const isWstETH = isWstETHToken(selectedAsset.symbol)
  const isLsETH = isLsETHToken(selectedAsset.symbol)
  const { L2StandardBridge, L2StandardBridgeProxy } = getSmartContracts(selectedAsset.symbol)

  const bigIntAmount = BigInt(ethers.parseUnits(amount, selectedAsset.decimals_l2).toString())

  const isSameReceivingAddress = !customReceivingAddress || customReceivingAddress === account

  const feeData = await provider.getFeeData()
  const gasPrice = feeData.gasPrice ?? 0n

  if (isLsETH) {
    // @ts-expect-error - Erc20Token | ExternalErc20Token Union type
    const abi = JSON.parse(selectedAsset.abi_l2)

    const allowance = (await readContract(wagmiConfig, {
      address: selectedAsset.contract_address_l2 as Address,
      abi,
      functionName: 'allowance',
      args: [account, L2StandardBridgeProxy.address],
    })) as bigint

    if (allowance < bigIntAmount) {
      const gas = await provider.estimateGas({
        from: account,
        to: selectedAsset.contract_address_l2,
        data: encodeFunctionData({
          abi,
          functionName: 'approve',
          args: [L2StandardBridgeProxy.address, bigIntAmount],
        }),
      })

      const hash = await writeContract(wagmiConfig, {
        address: selectedAsset.contract_address_l2 as Address,
        abi,
        functionName: 'approve',
        args: [L2StandardBridgeProxy.address, bigIntAmount],
        gas: getAdjustedGasAmount(gas, PESSIMISTIC_GAS_ESTIMATE_MULTIPLIER),
        gasPrice,
      })

      await waitForTransactionReceipt(wagmiConfig, { hash })
    }

    await refetchGasFee()
  }

  const gas = await provider.estimateGas({
    from: account,
    to: L2StandardBridgeProxy.address,
    data: encodeFunctionData({
      abi: L2StandardBridge.abi,
      functionName: getWithdrawERC20FunctionName(isWstETH, isSameReceivingAddress),
      // @ts-expect-error - Union type
      args: getWithdrawERC20Arguments(isWstETH, isSameReceivingAddress, {
        contractAddressL2: selectedAsset.contract_address_l2 as Address,
        contractAddressL1: selectedAsset.contract_address_l1 as Address,
        to: customReceivingAddress ?? account,
        amount: bigIntAmount,
        minGasLimit: BRIDGE_MIN_GAS_LIMIT_ERC20,
        extraData: '0x',
      }),
    }),
  })

  const withdrawERC20Hash = await writeContract(wagmiConfig, {
    address: L2StandardBridgeProxy.address,
    abi: L2StandardBridge.abi,
    functionName: getWithdrawERC20FunctionName(isWstETH, isSameReceivingAddress),
    // @ts-expect-error - Union type
    args: getWithdrawERC20Arguments(isWstETH, isSameReceivingAddress, {
      contractAddressL2: selectedAsset.contract_address_l2 as Address,
      contractAddressL1: selectedAsset.contract_address_l1 as Address,
      amount: bigIntAmount,
      to: customReceivingAddress ?? account,
      minGasLimit: BRIDGE_MIN_GAS_LIMIT_ERC20,
      extraData: '0x',
    }),
    gas: getAdjustedGasAmount(gas, PESSIMISTIC_GAS_ESTIMATE_MULTIPLIER),
    gasPrice,
  })

  setPendingTransactionHash(withdrawERC20Hash)

  const tx = await waitForTransactionReceipt(wagmiConfig, { hash: withdrawERC20Hash })

  return tx
}
