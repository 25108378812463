export const AlphanetOptimismPortal = {
  abi: [
    {
      type: 'constructor',
      inputs: [],
      stateMutability: 'nonpayable',
    },
    {
      type: 'receive',
      stateMutability: 'payable',
    },
    {
      type: 'function',
      name: 'depositTransaction',
      inputs: [
        {
          name: '_to',
          type: 'address',
          internalType: 'address',
        },
        {
          name: '_value',
          type: 'uint256',
          internalType: 'uint256',
        },
        {
          name: '_gasLimit',
          type: 'uint64',
          internalType: 'uint64',
        },
        {
          name: '_isCreation',
          type: 'bool',
          internalType: 'bool',
        },
        {
          name: '_data',
          type: 'bytes',
          internalType: 'bytes',
        },
      ],
      outputs: [],
      stateMutability: 'payable',
    },
    {
      type: 'function',
      name: 'donateETH',
      inputs: [],
      outputs: [],
      stateMutability: 'payable',
    },
    {
      type: 'function',
      name: 'finalizeWithdrawalTransaction',
      inputs: [
        {
          name: '_tx',
          type: 'tuple',
          internalType: 'struct Types.WithdrawalTransaction',
          components: [
            {
              name: 'nonce',
              type: 'uint256',
              internalType: 'uint256',
            },
            {
              name: 'sender',
              type: 'address',
              internalType: 'address',
            },
            {
              name: 'target',
              type: 'address',
              internalType: 'address',
            },
            {
              name: 'value',
              type: 'uint256',
              internalType: 'uint256',
            },
            {
              name: 'gasLimit',
              type: 'uint256',
              internalType: 'uint256',
            },
            {
              name: 'data',
              type: 'bytes',
              internalType: 'bytes',
            },
          ],
        },
      ],
      outputs: [],
      stateMutability: 'nonpayable',
    },
    {
      type: 'function',
      name: 'finalizedWithdrawals',
      inputs: [
        {
          name: '',
          type: 'bytes32',
          internalType: 'bytes32',
        },
      ],
      outputs: [
        {
          name: '',
          type: 'bool',
          internalType: 'bool',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'guardian',
      inputs: [],
      outputs: [
        {
          name: '',
          type: 'address',
          internalType: 'address',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'initialize',
      inputs: [
        {
          name: '_l2Oracle',
          type: 'address',
          internalType: 'contract L2OutputOracle',
        },
        {
          name: '_systemConfig',
          type: 'address',
          internalType: 'contract SystemConfig',
        },
        {
          name: '_superchainConfig',
          type: 'address',
          internalType: 'contract SuperchainConfig',
        },
      ],
      outputs: [],
      stateMutability: 'nonpayable',
    },
    {
      type: 'function',
      name: 'isOutputFinalized',
      inputs: [
        {
          name: '_l2OutputIndex',
          type: 'uint256',
          internalType: 'uint256',
        },
      ],
      outputs: [
        {
          name: '',
          type: 'bool',
          internalType: 'bool',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'l2Oracle',
      inputs: [],
      outputs: [
        {
          name: '',
          type: 'address',
          internalType: 'contract L2OutputOracle',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'l2Sender',
      inputs: [],
      outputs: [
        {
          name: '',
          type: 'address',
          internalType: 'address',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'minimumGasLimit',
      inputs: [
        {
          name: '_byteCount',
          type: 'uint64',
          internalType: 'uint64',
        },
      ],
      outputs: [
        {
          name: '',
          type: 'uint64',
          internalType: 'uint64',
        },
      ],
      stateMutability: 'pure',
    },
    {
      type: 'function',
      name: 'params',
      inputs: [],
      outputs: [
        {
          name: 'prevBaseFee',
          type: 'uint128',
          internalType: 'uint128',
        },
        {
          name: 'prevBoughtGas',
          type: 'uint64',
          internalType: 'uint64',
        },
        {
          name: 'prevBlockNum',
          type: 'uint64',
          internalType: 'uint64',
        },
        {
          name: 'prevTxCount',
          type: 'uint16',
          internalType: 'uint16',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'paused',
      inputs: [],
      outputs: [
        {
          name: 'paused_',
          type: 'bool',
          internalType: 'bool',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'proveWithdrawalTransaction',
      inputs: [
        {
          name: '_tx',
          type: 'tuple',
          internalType: 'struct Types.WithdrawalTransaction',
          components: [
            {
              name: 'nonce',
              type: 'uint256',
              internalType: 'uint256',
            },
            {
              name: 'sender',
              type: 'address',
              internalType: 'address',
            },
            {
              name: 'target',
              type: 'address',
              internalType: 'address',
            },
            {
              name: 'value',
              type: 'uint256',
              internalType: 'uint256',
            },
            {
              name: 'gasLimit',
              type: 'uint256',
              internalType: 'uint256',
            },
            {
              name: 'data',
              type: 'bytes',
              internalType: 'bytes',
            },
          ],
        },
        {
          name: '_l2OutputIndex',
          type: 'uint256',
          internalType: 'uint256',
        },
        {
          name: '_outputRootProof',
          type: 'tuple',
          internalType: 'struct Types.OutputRootProof',
          components: [
            {
              name: 'version',
              type: 'bytes32',
              internalType: 'bytes32',
            },
            {
              name: 'stateRoot',
              type: 'bytes32',
              internalType: 'bytes32',
            },
            {
              name: 'messagePasserStorageRoot',
              type: 'bytes32',
              internalType: 'bytes32',
            },
            {
              name: 'latestBlockhash',
              type: 'bytes32',
              internalType: 'bytes32',
            },
          ],
        },
        {
          name: '_withdrawalProof',
          type: 'bytes[]',
          internalType: 'bytes[]',
        },
      ],
      outputs: [],
      stateMutability: 'nonpayable',
    },
    {
      type: 'function',
      name: 'provenWithdrawals',
      inputs: [
        {
          name: '',
          type: 'bytes32',
          internalType: 'bytes32',
        },
      ],
      outputs: [
        {
          name: 'outputRoot',
          type: 'bytes32',
          internalType: 'bytes32',
        },
        {
          name: 'timestamp',
          type: 'uint128',
          internalType: 'uint128',
        },
        {
          name: 'l2OutputIndex',
          type: 'uint128',
          internalType: 'uint128',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'superchainConfig',
      inputs: [],
      outputs: [
        {
          name: '',
          type: 'address',
          internalType: 'contract SuperchainConfig',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'systemConfig',
      inputs: [],
      outputs: [
        {
          name: '',
          type: 'address',
          internalType: 'contract SystemConfig',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'version',
      inputs: [],
      outputs: [
        {
          name: '',
          type: 'string',
          internalType: 'string',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'event',
      name: 'GasBurned',
      inputs: [
        {
          name: 'gasAmount',
          type: 'uint256',
          indexed: false,
          internalType: 'uint256',
        },
        {
          name: 'sender',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
      ],
      anonymous: false,
    },
    {
      type: 'event',
      name: 'Initialized',
      inputs: [
        {
          name: 'version',
          type: 'uint64',
          indexed: false,
          internalType: 'uint64',
        },
      ],
      anonymous: false,
    },
    {
      type: 'event',
      name: 'TransactionDeposited',
      inputs: [
        {
          name: 'from',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
        {
          name: 'to',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
        {
          name: 'version',
          type: 'uint256',
          indexed: true,
          internalType: 'uint256',
        },
        {
          name: 'opaqueData',
          type: 'bytes',
          indexed: false,
          internalType: 'bytes',
        },
      ],
      anonymous: false,
    },
    {
      type: 'event',
      name: 'WithdrawalFinalized',
      inputs: [
        {
          name: 'withdrawalHash',
          type: 'bytes32',
          indexed: true,
          internalType: 'bytes32',
        },
        {
          name: 'success',
          type: 'bool',
          indexed: false,
          internalType: 'bool',
        },
      ],
      anonymous: false,
    },
    {
      type: 'event',
      name: 'WithdrawalProven',
      inputs: [
        {
          name: 'withdrawalHash',
          type: 'bytes32',
          indexed: true,
          internalType: 'bytes32',
        },
        {
          name: 'from',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
        {
          name: 'to',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
      ],
      anonymous: false,
    },
    {
      type: 'error',
      name: 'InvalidInitialization',
      inputs: [],
    },
    {
      type: 'error',
      name: 'NotInitializing',
      inputs: [],
    },
    {
      type: 'error',
      name: 'OutOfGas',
      inputs: [],
    },
  ],
} as const
