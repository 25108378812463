import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useThemeColors } from 'hooks/useThemeColors'

type MobileRowCellProps = {
  label: React.ReactNode
}

export const MobileRowCell = ({
  children,
  label,
  ...props
}: React.PropsWithChildren<MobileRowCellProps & React.ComponentProps<typeof Flex>>) => {
  const COLORS = useThemeColors()

  return (
    <Flex alignItems="center" {...props}>
      {typeof label === 'string' ? (
        <Text w="8.5rem" variant="text3regular" color={COLORS.grey03}>
          {label}
        </Text>
      ) : (
        <Box w="8.5rem">{label}</Box>
      )}
      {children}
    </Flex>
  )
}
