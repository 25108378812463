import { NON_STANDARD_BRIDGE_TOKENS } from 'constants/common'
import { AlphanetL1StandardBridge } from 'deployments/alphanet/L1StandardBridge'
import { AlphanetL1StandardBridgeProxy } from 'deployments/alphanet/L1StandardBridgeProxy'
import { AlphanetL2StandardBridge } from 'deployments/alphanet/L2StandardBridge'
import { AlphanetL2StandardBridgeProxy } from 'deployments/alphanet/L2StandardBridgeProxy'
import { AlphanetOptimismPortal } from 'deployments/alphanet/OptimismPortal'
import { AlphanetOptimismPortalProxy } from 'deployments/alphanet/OptimismPortalProxy'

import { BetanetL1StandardBridge } from 'deployments/betanet/L1StandardBridge'
import { BetanetL1StandardBridgeProxy } from 'deployments/betanet/L1StandardBridgeProxy'
import { BetanetL2StandardBridge } from 'deployments/betanet/L2StandardBridge'
import { BetanetL2StandardBridgeProxy } from 'deployments/betanet/L2StandardBridgeProxy'
import { BetanetOptimismPortal } from 'deployments/betanet/OptimismPortal'
import { BetanetOptimismPortalProxy } from 'deployments/betanet/OptimismPortalProxy'

import { Dev7702L1StandardBridge } from 'deployments/dev7702/L1StandardBridge'
import { Dev7702L1StandardBridgeProxy } from 'deployments/dev7702/L1StandardBridgeProxy'
import { Dev7702L2StandardBridge } from 'deployments/dev7702/L2StandardBridge'
import { Dev7702L2StandardBridgeProxy } from 'deployments/dev7702/L2StandardBridgeProxy'
import { Dev7702OptimismPortal } from 'deployments/dev7702/OptimismPortal'
import { Dev7702OptimismPortalProxy } from 'deployments/dev7702/OptimismPortalProxy'

import { GarfieldTestnetL1StandardBridge } from 'deployments/garfield-testnet/L1StandardBridge'
import { GarfieldTestnetL1StandardBridgeProxy } from 'deployments/garfield-testnet/L1StandardBridgeProxy'
import { GarfieldTestnetL2StandardBridge } from 'deployments/garfield-testnet/L2StandardBridge'
import { GarfieldTestnetL2StandardBridgeProxy } from 'deployments/garfield-testnet/L2StandardBridgeProxy'
import { GarfieldTestnetOptimismPortal } from 'deployments/garfield-testnet/OptimismPortal'
import { GarfieldTestnetOptimismPortalProxy } from 'deployments/garfield-testnet/OptimismPortalProxy'

import { LidoMainnetL1StandardBridge } from 'deployments/lido-mainnet/L1StandardBridge'
import { LidoMainnetL1StandardBridgeProxy } from 'deployments/lido-mainnet/L1StandardBridgeProxy'
import { LidoMainnetL2StandardBridge } from 'deployments/lido-mainnet/L2StandardBridge'
import { LidoMainnetL2StandardBridgeProxy } from 'deployments/lido-mainnet/L2StandardBridgeProxy'

import { LidoTestnetL1StandardBridge } from 'deployments/lido-testnet/L1StandardBridge'
import { LidoTestnetL1StandardBridgeProxy } from 'deployments/lido-testnet/L1StandardBridgeProxy'
import { LidoTestnetL2StandardBridge } from 'deployments/lido-testnet/L2StandardBridge'
import { LidoTestnetL2StandardBridgeProxy } from 'deployments/lido-testnet/L2StandardBridgeProxy'

import { MainnetL1StandardBridge } from 'deployments/mainnet/L1StandardBridge'
import { MainnetL1StandardBridgeProxy } from 'deployments/mainnet/L1StandardBridgeProxy'
import { MainnetL2StandardBridge } from 'deployments/mainnet/L2StandardBridge'
import { MainnetL2StandardBridgeProxy } from 'deployments/mainnet/L2StandardBridgeProxy'
import { MainnetOptimismPortal } from 'deployments/mainnet/OptimismPortal'
import { MainnetOptimismPortalProxy } from 'deployments/mainnet/OptimismPortalProxy'

import { TestnetL1StandardBridge } from 'deployments/testnet/L1StandardBridge'
import { TestnetL1StandardBridgeProxy } from 'deployments/testnet/L1StandardBridgeProxy'
import { TestnetL2StandardBridge } from 'deployments/testnet/L2StandardBridge'
import { TestnetL2StandardBridgeProxy } from 'deployments/testnet/L2StandardBridgeProxy'
import { TestnetOptimismPortal } from 'deployments/testnet/OptimismPortal'
import { TestnetOptimismPortalProxy } from 'deployments/testnet/OptimismPortalProxy'

import { env } from 'env.client'

const ALPHANET_CONTRACT = {
  L1StandardBridge: AlphanetL1StandardBridge,
  L1StandardBridgeProxy: AlphanetL1StandardBridgeProxy,
  L2StandardBridge: AlphanetL2StandardBridge,
  L2StandardBridgeProxy: AlphanetL2StandardBridgeProxy,
  OptimismPortal: AlphanetOptimismPortal,
  OptimismPortalProxy: AlphanetOptimismPortalProxy,
  DEFAULT_L1_CONTRACT_ADDRESSES: {
    // Source: https://github.com/zircuit-labs/zkr-monorepo/blob/develop/packages/contracts-bedrock/zr-e2e-test/src/withdrawals.ts#L27
    L1CrossDomainMessenger: '0x769E73C1A91825b904524B5B028c18e09367266d',
    L1StandardBridge: AlphanetL1StandardBridgeProxy.address,
    OptimismPortal: AlphanetOptimismPortalProxy.address,
    L2OutputOracle: '0xae20F6Eabe35021B0CE74F924E3Be517DB59e42A',
    AddressManager: '0x0000000000000000000000000000000000000000',
    StateCommitmentChain: '0x0000000000000000000000000000000000000000',
    CanonicalTransactionChain: '0x0000000000000000000000000000000000000000',
    BondManager: '0x0000000000000000000000000000000000000000',
  },
}

export const getSmartContracts = (symbol?: string) => {
  if (symbol === NON_STANDARD_BRIDGE_TOKENS.wstETH) {
    if (env.NEXT_PUBLIC_NETWORK === 'testnet') {
      return {
        L1StandardBridge: LidoTestnetL1StandardBridge,
        L1StandardBridgeProxy: LidoTestnetL1StandardBridgeProxy,
        L2StandardBridge: LidoTestnetL2StandardBridge,
        L2StandardBridgeProxy: LidoTestnetL2StandardBridgeProxy,
      }
    }
    if (env.NEXT_PUBLIC_NETWORK === 'mainnet') {
      return {
        L1StandardBridge: LidoMainnetL1StandardBridge,
        L1StandardBridgeProxy: LidoMainnetL1StandardBridgeProxy,
        L2StandardBridge: LidoMainnetL2StandardBridge,
        L2StandardBridgeProxy: LidoMainnetL2StandardBridgeProxy,
      }
    }
  }
  if (env.NEXT_PUBLIC_NETWORK === 'alphanet') {
    return ALPHANET_CONTRACT
  }
  if (env.NEXT_PUBLIC_NETWORK === 'betanet') {
    return {
      L1StandardBridge: BetanetL1StandardBridge,
      L1StandardBridgeProxy: BetanetL1StandardBridgeProxy,
      L2StandardBridge: BetanetL2StandardBridge,
      L2StandardBridgeProxy: BetanetL2StandardBridgeProxy,
      OptimismPortal: BetanetOptimismPortal,
      OptimismPortalProxy: BetanetOptimismPortalProxy,
      DEFAULT_L1_CONTRACT_ADDRESSES: {
        // Source: https://github.com/zircuit-labs/zkr-monorepo/blob/develop/packages/contracts-bedrock/zr-e2e-test/src/withdrawals.ts#L27
        L1CrossDomainMessenger: '0xC5cd15d308E25a212b4c2c2E9B7449f7c67A2067',
        L1StandardBridge: BetanetL1StandardBridgeProxy.address,
        OptimismPortal: BetanetOptimismPortalProxy.address,
        L2OutputOracle: '0xDC2da88C1d0FEf8AD63b93d9829552983EEADc46',
        AddressManager: '0x0000000000000000000000000000000000000000',
        StateCommitmentChain: '0x0000000000000000000000000000000000000000',
        CanonicalTransactionChain: '0x0000000000000000000000000000000000000000',
        BondManager: '0x0000000000000000000000000000000000000000',
      },
    }
  }
  if (env.NEXT_PUBLIC_NETWORK === 'dev7702') {
    return {
      L1StandardBridge: Dev7702L1StandardBridge,
      L1StandardBridgeProxy: Dev7702L1StandardBridgeProxy,
      L2StandardBridge: Dev7702L2StandardBridge,
      L2StandardBridgeProxy: Dev7702L2StandardBridgeProxy,
      OptimismPortal: Dev7702OptimismPortal,
      OptimismPortalProxy: Dev7702OptimismPortalProxy,
      DEFAULT_L1_CONTRACT_ADDRESSES: {
        // Source: https://github.com/zircuit-labs/zkr-monorepo/blob/develop/packages/contracts-bedrock/zr-e2e-test/src/withdrawals.ts#L27
        L1CrossDomainMessenger: '0xB933989aa6121000B9705887Fb16dfA84C28F9A5',
        L1StandardBridge: Dev7702L1StandardBridgeProxy.address,
        OptimismPortal: Dev7702OptimismPortalProxy.address,
        L2OutputOracle: '0xDf9980A75efD941ADB12dD8b3Bbe9aD646c2BAD4',
        AddressManager: '0x0000000000000000000000000000000000000000',
        StateCommitmentChain: '0x0000000000000000000000000000000000000000',
        CanonicalTransactionChain: '0x0000000000000000000000000000000000000000',
        BondManager: '0x0000000000000000000000000000000000000000',
      },
    }
  }
  if (env.NEXT_PUBLIC_NETWORK === 'testnet') {
    return {
      L1StandardBridge: TestnetL1StandardBridge,
      L1StandardBridgeProxy: TestnetL1StandardBridgeProxy,
      L2StandardBridge: TestnetL2StandardBridge,
      L2StandardBridgeProxy: TestnetL2StandardBridgeProxy,
      OptimismPortal: TestnetOptimismPortal,
      OptimismPortalProxy: TestnetOptimismPortalProxy,
      DEFAULT_L1_CONTRACT_ADDRESSES: {
        // Source: https://github.com/zircuit-labs/zkr-monorepo/blob/develop/packages/contracts-bedrock/zr-e2e-test/src/withdrawals.ts#L27
        L1CrossDomainMessenger: '0x2De7B7364A37fBB35F946cA7175A1b596710b262',
        L1StandardBridge: TestnetL1StandardBridgeProxy.address,
        OptimismPortal: TestnetOptimismPortalProxy.address,
        L2OutputOracle: '0x740C2dac453aEf7140809F80b72bf0e647af8148',
        AddressManager: '0x0000000000000000000000000000000000000000',
        StateCommitmentChain: '0x0000000000000000000000000000000000000000',
        CanonicalTransactionChain: '0x0000000000000000000000000000000000000000',
        BondManager: '0x0000000000000000000000000000000000000000',
      },
    }
  }
  if (env.NEXT_PUBLIC_NETWORK === 'garfield-testnet') {
    return {
      L1StandardBridge: GarfieldTestnetL1StandardBridge,
      L1StandardBridgeProxy: GarfieldTestnetL1StandardBridgeProxy,
      L2StandardBridge: GarfieldTestnetL2StandardBridge,
      L2StandardBridgeProxy: GarfieldTestnetL2StandardBridgeProxy,
      OptimismPortal: GarfieldTestnetOptimismPortal,
      OptimismPortalProxy: GarfieldTestnetOptimismPortalProxy,
      DEFAULT_L1_CONTRACT_ADDRESSES: {
        // Source: https://github.com/zircuit-labs/zkr-monorepo/blob/develop/packages/contracts-bedrock/zr-e2e-test/src/withdrawals.ts#L27
        L1CrossDomainMessenger: '0x6aC894b2a32fED0DC09c2c617277c2F2BF1cf130',
        L1StandardBridge: GarfieldTestnetL1StandardBridgeProxy.address,
        OptimismPortal: GarfieldTestnetOptimismPortalProxy.address,
        L2OutputOracle: '0xd69D3AC5CA686cCF94b258291772bc520FEAf211',
        AddressManager: '0x0000000000000000000000000000000000000000',
        StateCommitmentChain: '0x0000000000000000000000000000000000000000',
        CanonicalTransactionChain: '0x0000000000000000000000000000000000000000',
        BondManager: '0x0000000000000000000000000000000000000000',
      },
    }
  }
  if (env.NEXT_PUBLIC_NETWORK === 'mainnet') {
    return {
      L1StandardBridge: MainnetL1StandardBridge,
      L1StandardBridgeProxy: MainnetL1StandardBridgeProxy,
      L2StandardBridge: MainnetL2StandardBridge,
      L2StandardBridgeProxy: MainnetL2StandardBridgeProxy,
      OptimismPortal: MainnetOptimismPortal,
      OptimismPortalProxy: MainnetOptimismPortalProxy,
      DEFAULT_L1_CONTRACT_ADDRESSES: {
        // Source: https://github.com/zircuit-labs/zkr-monorepo/blob/develop/packages/contracts-bedrock/zr-e2e-test/src/withdrawals.ts#L27
        L1CrossDomainMessenger: '0x2a721cBE81a128be0F01040e3353c3805A5EA091',
        L1StandardBridge: MainnetL1StandardBridgeProxy.address,
        OptimismPortal: MainnetOptimismPortalProxy.address,
        L2OutputOracle: '0x92Ef6Af472b39F1b363da45E35530c24619245A4',
        AddressManager: '0x0000000000000000000000000000000000000000',
        StateCommitmentChain: '0x0000000000000000000000000000000000000000',
        CanonicalTransactionChain: '0x0000000000000000000000000000000000000000',
        BondManager: '0x0000000000000000000000000000000000000000',
      },
    }
  }

  // Fallback
  return ALPHANET_CONTRACT
}
