import { Button, Flex } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { TransactionButtonTimer } from 'components/TransactionButtonTimer'
import {
  EXIT_TXN_STATUS_LABELS,
  EXPECTED_TRANSACTION_BUTTON_WAITING_TIME_MAINNET_IN_HOURS,
  EXPECTED_TRANSACTION_BUTTON_WAITING_TIME_OTHER_NETWORKS_IN_HOURS,
} from 'constants/common'
import { env } from 'env.client'
import {
  RecentTransactionsListItemExitStatus as TxItemExitStatus,
  RecentTransactionsListItemStatus as TxItemStatus,
} from 'generated/reactQueryClient'
import type { UpdatedTransactionType } from 'hooks/useUpdatedTransactions'

type TransactionButtonProps = {
  transaction: UpdatedTransactionType
  txInExecution: string | null
  hasValidExitStatus: boolean
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  onTransactionFinalized: () => Promise<void>
}

export const TransactionButton = ({
  transaction,
  txInExecution,
  hasValidExitStatus,
  onClick,
  onTransactionFinalized,
  ...props
}: TransactionButtonProps &
  Omit<
    React.ComponentProps<typeof Button>,
    'onClick' | 'isLoading' | 'isDisabled' | 'variant'
  >) => {
  const { t } = useTranslation(['common'])

  return hasValidExitStatus ? (
    <Button
      isLoading={txInExecution === transaction.l2TxHash}
      isDisabled={
        transaction.exitStatus === TxItemExitStatus.waiting ||
        transaction.exitStatus === TxItemExitStatus.in_challenge_period ||
        transaction.status === TxItemStatus.pending
      }
      onClick={onClick}
      {...props}
    >
      <Flex gap={1} flexDir="column" alignItems="center">
        {transaction.exitStatus === TxItemExitStatus.waiting
          ? `${t('TransactionsTable.WaitApproximatelyX', {
              duration: t('hours', {
                count:
                  env.NEXT_PUBLIC_NETWORK === 'mainnet'
                    ? EXPECTED_TRANSACTION_BUTTON_WAITING_TIME_MAINNET_IN_HOURS
                    : EXPECTED_TRANSACTION_BUTTON_WAITING_TIME_OTHER_NETWORKS_IN_HOURS,
              }),
            })}`
          : EXIT_TXN_STATUS_LABELS[transaction.exitStatus]}
        <TransactionButtonTimer
          timeLeftToFinalize={transaction.timeLeftToFinalize ?? 0}
          exitStatus={transaction.exitStatus}
          onTransactionFinalized={onTransactionFinalized}
        />
      </Flex>
    </Button>
  ) : (
    <Button variant="tertiary" onClick={onClick} {...props}>
      {t('BridgeAgain')}
    </Button>
  )
}
