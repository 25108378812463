import { Box, Flex, Text } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useThemeColors } from 'hooks/useThemeColors'

const AnimatedFlex = motion.create(Flex)

type AnimatedMobileRowCellProps = {
  label: React.ReactNode
  isVisible: boolean
}

export const AnimatedMobileRowCell = ({
  children,
  label,
  isVisible,
  ...props
}: React.PropsWithChildren<
  AnimatedMobileRowCellProps & React.ComponentProps<typeof AnimatedFlex>
>) => {
  const COLORS = useThemeColors()

  return (
    <AnimatePresence mode="sync">
      {isVisible && (
        <AnimatedFlex
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{
            duration: 0.3,
            ease: [0.25, 0.1, 0.25, 1],
            opacity: { duration: 0.15 },
            height: { duration: 0.15 },
          }}
          alignItems="center"
          overflow="hidden"
          willChange="opacity, height"
          layout
          style={{ position: 'relative' }}
          {...props}
        >
          {typeof label === 'string' ? (
            <Text w="8.5rem" variant="text3regular" color={COLORS.grey03}>
              {label}
            </Text>
          ) : (
            <Box w="8.5rem">{label}</Box>
          )}
          {children}
        </AnimatedFlex>
      )}
    </AnimatePresence>
  )
}
