import type { Address } from 'viem'

export const getWithdrawERC20Arguments = (
  isWstETH: boolean,
  isSameReceivingAddress: boolean,
  {
    contractAddressL2,
    contractAddressL1,
    to,
    amount,
    minGasLimit,
    extraData,
  }: {
    contractAddressL2: Address
    contractAddressL1: Address
    to: Address
    amount: bigint
    minGasLimit: number
    extraData: Address
  },
) => {
  if (isWstETH) {
    return isSameReceivingAddress
      ? [contractAddressL2, amount, minGasLimit, extraData] // withdraw
      : [contractAddressL2, to, amount, minGasLimit, extraData] // withdrawTo
  }

  return isSameReceivingAddress
    ? [contractAddressL2, contractAddressL1, amount, minGasLimit, extraData] // bridgeERC20
    : [contractAddressL2, contractAddressL1, to, amount, minGasLimit, extraData] // bridgeERC20To
}
