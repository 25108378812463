import { erc20Abi } from 'viem'
import type { Abi, Address } from 'viem'
import { publicClientL1, publicClientL2 } from 'lib/viem'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'
import { isWstETHToken, isZrcToken } from 'utils/common'
import { captureError } from 'utils/sentry'

const EXPECTED_ERROR_MESSAGES = {
  gudBridgeModifier: 'Unauthorized bridge',
  onlyBridgeCanMintAndBurn: 'only bridge can mint and burn',
  lidoOnlyBridgeCanMintAndBurn: 'ErrorNotBridge',
}

type ValidateTokenProps = {
  address: Address
  l1TokenAddress: Address
  l2TokenAddress: Address
  l2TokenAbi: Abi
}

const validateBridgeError = (error: Error, isWstETH: boolean): boolean => {
  if (error.message.includes(EXPECTED_ERROR_MESSAGES.onlyBridgeCanMintAndBurn)) {
    return true
  }

  if (error.message.includes(EXPECTED_ERROR_MESSAGES.gudBridgeModifier)) {
    return true
  }

  if (isWstETH && error.message.includes(EXPECTED_ERROR_MESSAGES.lidoOnlyBridgeCanMintAndBurn)) {
    return true
  }
  return false
}

/**
 *  Check whether target erc20 token is valid for bridging on our network.
 *  @param address: Connected account's address
 *  @param l1TokenAddress: L1 Address of the erc20 token
 *  @param l2TokenAddress: L2 Address of the erc20 token
 *  @param l2TokenAbi: L2 Abi of the erc20 token
 * */
export const validateERC20Token = async ({
  address,
  l1TokenAddress,
  l2TokenAddress,
  l2TokenAbi,
}: ValidateTokenProps) => {
  const symbol = (await publicClientL2.readContract({
    address: l2TokenAddress,
    abi: l2TokenAbi,
    functionName: 'symbol',
    args: [],
  })) as string

  const { L2StandardBridgeProxy } = getSmartContracts(symbol)

  const isWstETH = isWstETHToken(symbol)
  const isZrc = isZrcToken(symbol)

  if (!isWstETH) {
    try {
      const remoteTokenAddressL1 = await publicClientL2.readContract({
        address: l2TokenAddress,
        abi: l2TokenAbi,
        functionName: 'remoteToken',
        args: [],
      })

      // Ensure that remoteTokenAddress on l2TokenAddress is equal to l1TokenAddress.
      if (remoteTokenAddressL1 !== l1TokenAddress) {
        captureError(
          new Error("Remote token address on l2 token doesn't match the l1 token address."),
          {
            category: 'validateERC20Token',
            data: { remoteTokenAddressL1, l1TokenAddress },
            level: 'error',
          },
        )
        return null
      }
    } catch (error) {
      captureError(error as Error, {
        category: 'validateERC20Token',
        data: { l2TokenAddress, l2TokenAbi },
        level: 'error',
      })
      return null
    }
  }

  const l1TokenDecimals = await publicClientL1.readContract({
    address: l1TokenAddress,
    abi: erc20Abi,
    functionName: 'decimals',
  })

  const l2TokenDecimals = await publicClientL2.readContract({
    address: l2TokenAddress,
    abi: erc20Abi,
    functionName: 'decimals',
  })

  // Ensure both decimals are equal.
  if (l1TokenDecimals !== l2TokenDecimals) {
    captureError(new Error("Token decimals aren't equal."), {
      category: 'validateERC20Token',
      data: { l1TokenDecimals, l2TokenDecimals },
      level: 'error',
    })
    return null
  }

  const l2Bridge = await publicClientL2.readContract({
    address: l2TokenAddress,
    abi: l2TokenAbi,
    functionName: 'bridge',
    args: [],
  })

  // Ensure that bridge address is set correctly.
  if (l2Bridge !== L2StandardBridgeProxy.address) {
    captureError(
      new Error("Bridge address of l2 token doesn't match our standard bridge's proxy address."),
      {
        category: 'validateERC20Token',
        data: { l2Bridge, L2StandardBridgeProxyAddress: L2StandardBridgeProxy.address },
        level: 'error',
      },
    )

    return null
  }

  // Ensure that only Bridge can Mint and Burn.
  try {
    await publicClientL2.simulateContract({
      account: isZrc ? L2StandardBridgeProxy.address : address,
      address: l2TokenAddress,
      abi: l2TokenAbi,
      functionName: isWstETH ? 'bridgeMint' : 'mint',
      args: [address, 0],
    })
  } catch (error) {
    if (error instanceof Error && validateBridgeError(error, isWstETH)) {
      // Expected error
    } else {
      captureError(error as Error, {
        category: 'validateERC20Token',
        data: { l2TokenAddress, l2TokenAbi },
        level: 'error',
      })

      return null
    }
  }

  try {
    await publicClientL2.simulateContract({
      account: isZrc ? L2StandardBridgeProxy.address : address,
      address: l2TokenAddress,
      abi: l2TokenAbi,
      functionName: isWstETH ? 'bridgeBurn' : 'burn',
      args: [address, 0],
    })
  } catch (error) {
    if (error instanceof Error && validateBridgeError(error, isWstETH)) {
      // Expected error
    } else {
      captureError(error as Error, {
        category: 'validateERC20Token',
        data: { l2TokenAddress, l2TokenAbi },
        level: 'error',
      })

      return null
    }
  }

  try {
    await publicClientL2.simulateContract({
      account: L2StandardBridgeProxy.address,
      address: l2TokenAddress,
      abi: l2TokenAbi,
      functionName: isWstETH ? 'bridgeMint' : 'mint',
      args: [L2StandardBridgeProxy.address, 0],
    })
  } catch (error) {
    if (error instanceof Error && validateBridgeError(error, isWstETH)) {
      // Expected error
    } else {
      captureError(error as Error, {
        category: 'validateERC20Token',
        data: {
          l2TokenAddress,
          l2TokenAbi,
          L2StandardBridgeProxyAddress: L2StandardBridgeProxy.address,
        },
        level: 'error',
      })
      return null
    }
  }

  try {
    await publicClientL2.simulateContract({
      account: L2StandardBridgeProxy.address,
      address: l2TokenAddress,
      abi: l2TokenAbi,
      functionName: isWstETH ? 'bridgeBurn' : 'burn',
      args: [L2StandardBridgeProxy.address, 0],
    })
  } catch (error) {
    if (error instanceof Error && validateBridgeError(error, isWstETH)) {
      // Expected error
    } else {
      captureError(error as Error, {
        category: 'validateERC20Token',
        data: {
          l2TokenAddress,
          l2TokenAbi,
          L2StandardBridgeProxyAddress: L2StandardBridgeProxy.address,
        },
        level: 'error',
      })
      return null
    }
  }

  return { l1TokenDecimals, l2TokenDecimals, symbol }
}
