import type { Address } from 'viem'

export const getDepositERC20Arguments = (
  isWstETH: boolean,
  isSameReceivingAddress: boolean,
  {
    contractAddressL2,
    contractAddressL1,
    to,
    amount,
    minGasLimit,
    extraData,
  }: {
    contractAddressL2: Address
    contractAddressL1: Address
    to: Address
    amount: bigint
    minGasLimit: number
    extraData: Address
  },
) => {
  if (isWstETH) {
    return isSameReceivingAddress
      ? [contractAddressL1, contractAddressL2, amount, minGasLimit, extraData] // depositERC20
      : [contractAddressL1, contractAddressL2, to, amount, minGasLimit, extraData] // depositERC20To
  }

  return isSameReceivingAddress
    ? [contractAddressL1, contractAddressL2, amount, minGasLimit, extraData] // bridgeERC20
    : [contractAddressL1, contractAddressL2, to, amount, minGasLimit, extraData] // bridgeERC20To
}
