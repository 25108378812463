import type { LayoutProps, CardProps } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Icon,
  Table as ChakraTable,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  Accordion,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { RiInformationLine } from 'react-icons/ri'
import PulseLoader from 'react-spinners/PulseLoader'
import { useIsUpToDesktopWidth } from 'components/Breakpoint'
import { InfoPopup } from 'components/InfoPopup'
import { Pagination } from 'components/Pagination'
import { COLOR_MODE } from 'constants/common'
import { useThemeColors } from 'hooks/useThemeColors'
import { useWalletContext } from 'providers/WalletProvider'
import { AnimatedMobileRowCell } from './AnimatedMobileRowCell'
import { MobileRow } from './MobileRow'
import { MobileRowCell } from './MobileRowCell'

type TableProps<DataEntry> = {
  hasNextPage?: boolean
  hasPreviousPage?: boolean
  onNextPage?: () => void
  onPreviousPage?: () => void
  title?: string
  titleTooltip?: string
  data: DataEntry[]
  renderTableHeader?: () => React.ReactElement
  renderTableRow: (row: DataEntry) => React.ReactElement
  renderMobileRow: (row: DataEntry) => React.ReactElement
  additionalHeaderContent?: React.ReactElement
  hasPagination?: boolean
  hasTopContent?: boolean
  isLoading: boolean
  variant?: string
  loaderHeight?: LayoutProps['height']
} & CardProps

const Loader = ({ isLoading, height }: { isLoading: boolean; height: LayoutProps['height'] }) => {
  const COLORS = useThemeColors()
  const { isConnectedAddressMultisigWallet, isConnectedMultisigWalletLoading } = useWalletContext()
  const { t } = useTranslation(['common'])

  return (
    <Flex h={height} justifyContent="center" alignItems="center" gap={1.5}>
      {isLoading || isConnectedMultisigWalletLoading ? (
        <PulseLoader size={4} color={COLORS.dark01} />
      ) : (
        <Icon as={RiInformationLine} boxSize={3.5} />
      )}
      <Text variant="text2regular">
        {isLoading || isConnectedMultisigWalletLoading ? (
          t('Loading')
        ) : (
          <>
            {isConnectedAddressMultisigWallet
              ? t('Table.TransactionsHistoryIsNotAvailableForMultisigWallets')
              : t('Table.ThereAreNoMatchingEntries')}
          </>
        )}
      </Text>
    </Flex>
  )
}

export const Table = <T,>({
  data,
  hasNextPage,
  hasPreviousPage,
  onNextPage,
  onPreviousPage,
  title,
  titleTooltip,
  renderTableRow,
  renderTableHeader,
  renderMobileRow,
  additionalHeaderContent,
  hasPagination = true,
  hasTopContent = true,
  isLoading,
  variant = 'primary',
  loaderHeight = '70vh',
  ...props
}: TableProps<T>) => {
  const COLORS = useThemeColors()
  const { colorMode } = useColorMode()
  const isEmpty = data.length === 0
  const { isUpToDesktopWidth } = useIsUpToDesktopWidth()

  const shouldRenderPagination =
    hasPagination &&
    !!onNextPage &&
    !!onPreviousPage &&
    hasNextPage !== undefined &&
    hasPreviousPage !== undefined

  return (
    <Box h="100%" {...props}>
      {hasTopContent && (
        <Flex gap={5} mb={5} alignItems="center">
          <Flex flex={1} alignItems="center" gap={{ base: 1, md: 3 }}>
            {title && (
              <Flex alignItems="center" gap={1}>
                <Text
                  {...(renderTableHeader
                    ? { variant: { base: 'title2medium', md: 'heading4medium' } }
                    : { color: COLORS.grey02 })}
                  textTransform="capitalize"
                >
                  {title}
                </Text>
                {titleTooltip && (
                  <InfoPopup title={title}>
                    <Text {...(!renderTableHeader && { color: COLORS.grey02 })}>
                      {titleTooltip}
                    </Text>
                  </InfoPopup>
                )}
              </Flex>
            )}
            {additionalHeaderContent && additionalHeaderContent}
          </Flex>
          {!isUpToDesktopWidth && shouldRenderPagination && (
            <Pagination
              ml="auto"
              isDisabled={isLoading || isEmpty}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
            />
          )}
        </Flex>
      )}
      {!isUpToDesktopWidth && (
        <TableContainer
          bgColor={colorMode === COLOR_MODE.LIGHT ? 'grey07' : 'grey09'}
          borderRadius={10}
        >
          <ChakraTable variant={variant}>
            {renderTableHeader && (
              <Thead>
                <Tr>{renderTableHeader()}</Tr>
              </Thead>
            )}
            {isLoading || isEmpty ? (
              <TableCaption py={0}>
                <Loader isLoading={isLoading} height={loaderHeight} />
              </TableCaption>
            ) : (
              <Tbody>{data.map(renderTableRow)}</Tbody>
            )}
          </ChakraTable>
        </TableContainer>
      )}
      {isUpToDesktopWidth && (
        <Flex flexDirection="column" bg={COLORS.bgSecondary} borderRadius={10}>
          {shouldRenderPagination && (
            <Flex px={5} py={4}>
              <Pagination
                ml="auto"
                isDisabled={isLoading || isEmpty}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
              />
            </Flex>
          )}
          {isLoading || isEmpty ? (
            <Loader isLoading={isLoading} height={loaderHeight} />
          ) : (
            <Accordion allowMultiple variant="list">
              {data.map(renderMobileRow)}
            </Accordion>
          )}
        </Flex>
      )}
    </Box>
  )
}

Table.HeaderCell = Th
Table.Row = Tr
Table.RowCell = Td
Table.MobileRow = MobileRow
Table.MobileRowCell = MobileRowCell
Table.AnimatedMobileRowCell = AnimatedMobileRowCell
