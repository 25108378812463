import type { Theme } from '@chakra-ui/react'
import { useMediaQuery, useTheme } from '@chakra-ui/react'

export const useIsTabletWidth = () => {
  const {
    breakpoints: { md },
  } = useTheme<Theme>()
  const [isTabletWidth] = useMediaQuery(`(max-width: calc(${md} - 1px))`)

  return { isTabletWidth }
}
