import { AccordionButton, AccordionIcon, AccordionItem } from '@chakra-ui/react'
import React from 'react'

type MobileRowProps = {
  children?:
    | React.ReactNode
    | ((props: { isExpanded: boolean; isDisabled: boolean }) => React.ReactNode)
}

export const MobileRow = ({ children }: MobileRowProps) => (
  <AccordionItem>
    {(props) => (
      <AccordionButton>
        {typeof children === 'function' ? children(props) : children}
        <AccordionIcon />
      </AccordionButton>
    )}
  </AccordionItem>
)
