import { ETH } from 'constants/network'
import { env } from 'env.client'

type GetImageSrc = {
  symbol: string
  contract_address_l2?: string
  bridge?: boolean
  isManual?: boolean
}

export const getImageSrc = ({ symbol, contract_address_l2, bridge, isManual }: GetImageSrc) => {
  if (symbol === ETH.symbol) {
    return `/assets/${ETH.image}.svg`
  }

  if (bridge) {
    return `${env.NEXT_PUBLIC_ERC20_CDN_URL}/${env.NEXT_PUBLIC_NETWORK}/external/${contract_address_l2}.svg`
  }

  if (isManual) {
    return `/assets/grayscale-eth.svg`
  }

  return `${env.NEXT_PUBLIC_ERC20_CDN_URL}/${env.NEXT_PUBLIC_NETWORK}/${contract_address_l2}.svg`
}
